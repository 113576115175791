:root {
  --bg: #f3f9f9;
  --green: #4e663b;
  --yellow: #f79d05;

  --primary-purple: #652765;
  --secondary-purple: #a1789b;
  --primary-green: #06a864;
  --secondary-green: #12655a;

  /* --primary-clr: #003366; */
  /* --primary-clr: #6C0345; */
  --primary-clr: #308db9;

  /* --primary-clr: #00c9b7; */
  /* --primary-clr: #eb2e36; */
  /* --primary-clr: #735da5; */
  /* --primary-clr: #f98866; */
  /* --primary-clr: #9a0001; */
  --secondary-clr: #ffd700;
  /* --secondary-clr: #382b3d; */
  /* --secondary-clr: #845EC2; */
  /* --secondary-clr: #fff2d7; */
  /* --secondary-clr: #fcf7f7; */
  /* --secondary-clr: #d3c5e5; */
  --primary-dark: #1b1b1b;
  --secondary-dark: #4a4a4a;
  --primary-light: #ffffff;
  --secondary-light: #e6e6e6;
}

body {
  margin: 0px;
  color: var(--primary-dark);
  font-family: "Lato", sans-serif !important;
}

p {
  margin: 0;
}

span,
a {
  display: inline-block;
  text-decoration: none;
}

.ff-lato {
  font-family: "Lato", sans-serif !important;
}

.ff-jost {
  font-family: "Jost", sans-serif !important;
}

textarea.form-control,
input.form-control:focus,
select.form-select:focus,
textarea.form-control:focus,
input.form-check-input:focus {
  outline: 0px;
  box-shadow: none;
}

.dropdown-item:active,
.dropdown-item:active {
  background-color: #f8f9fa;
  color: var(--primary-dark);
}

.btn-thm-1 {
  border: none;
  border-radius: 0;
  color: var(--primary-dark);
  background-color: transparent;
}

.btn-thm-1:active,
.btn-thm-1:hover {
  border: none !important;
  background-color: transparent !important;
}

.btn-thm-1 span:not(:has(svg)) {
  font-weight: 600;
  color: var(--primary-clr);
}

.btn-thm-1 span:has(svg) {
  height: 34px;
  width: 34px;
  padding: 5px;
  margin-right: 8px;
  border-radius: 50px;
  color: var(--primary-light);
  background-color: var(--primary-clr);
}

.primary-btn-1 {
  border-radius: 0;
  font-weight: 500;
  color: var(--primary-clr);
  text-transform: capitalize;
  transition: all 200ms ease;
  padding: 8px 16px;
  background-color: var(--secondary-clr);
  border: 2px solid var(--secondary-clr);
}

.primary-btn-1:active,
.primary-btn-1:hover {
  color: var(--primary-clr) !important;
  border: 2px solid var(--primary-clr) !important;
  background-color: var(--secondary-clr) !important;
}

.primary-btn-1:active {
  transform: scale(1.05);
}

.border-btn {
  color: white;
  border-radius: 0;
  font-weight: 600;
  padding: 8px 16px;
  letter-spacing: 2px;
  backdrop-filter: blur(4px);
  text-transform: capitalize;
  transition: all 200ms ease;
  background-color: transparent;
  border: 3px solid var(--secondary-clr);
}

.border-btn:active,
.border-btn:hover {
  background-color: transparent !important;
  border: 3px solid var(--secondary-clr) !important;
}

.border-btn:active {
  transform: scale(1.05);
}

/* ------------------ */

.gradient-bg {
  background: linear-gradient(
    90deg,
    rgba(0, 51, 102, 1) 45%,
    rgba(255, 215, 0, 1) 100%
  );
}

.gradient-yellow {
  background: linear-gradient(
    90deg,
    rgba(255, 215, 0, 1),
    rgba(0, 51, 102, 1) 45% 100%
  );
}

.box-wide-shadow {
  box-shadow: 40px 40px 0px 3px #003366;
}

span.tag {
  display: inline-block;
  position: absolute;
  top: 0;
  background: #ffd700;
  padding: 2px 11px;
  right: 10px;
  color: #003366;
  border-radius: 7px;
  top: 10px;
}

.link-img-wrapper img {
  width: 100% !important;
  height: 200px;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.link-img-wrapper:hover img {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}

.overlay-item {
  width: 100%;
  height: 100%;
  background: #000000b5;
  position: absolute;
  top: 0;
}

.form-wrapper.form-box {
  padding: 30px;
  margin: 0 auto;
  border: 1px solid #dedede;
  border-radius: 4px;
}

.input-field .form-select,
.input-field .custom-field {
  width: 100%;
  padding: 10px;
  font-size: 17px;
  border: 1px solid #dededede;
  border-radius: 4px;
}

.form-wrapper .input-field {
  margin-bottom: 15px;
  position: relative;
}

.input-field label {
  margin-bottom: 10px;
  font-size: 17px;
  color: #000;
}

span.hide-password {
  position: absolute;
  right: 15px;
  bottom: 11px;
}

.form-btn .forget-text {
  display: inline-block;
  width: 100%;
  margin-bottom: 15px;
}

.form-box h2 {
  color: #6c0345;
}

.form-btn button.form-button {
  font-weight: 600;
  width: 100%;
  border: 0;
  margin-bottom: 15px;
  background: #ffd700;
  padding: 10px;
  font-size: 18px;
  color: #003366;
  border-radius: 50px;
}
