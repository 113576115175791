.page-wrapper aside.sidebar-div {
  top: 0;
  left: 0;
  bottom: 0;
  width: 250px;
  overflow-y: auto;
  position: fixed;
}

.page-wrapper aside.sidebar-div::-webkit-scrollbar {
  width: 5px !important;
  background-color: #fff;
}

.page-wrapper aside.sidebar-div::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: var(--primary-clr);
}

.page-wrapper main.admin-layout {
  display: flex;
  min-height: 100vh;
  /* grid-template-rows: auto 1fr auto; */
  margin-left: 250px;
  flex-direction: column;
  width: calc(100% - 250px);
}

.page-wrapper footer,
.page-wrapper header {
  color: #fff;
  padding: 0px 20px;
  /* background-color: var(--yellow); */
}

.page-wrapper aside {
  background-color: var(--primary-clr);
}

.page-wrapper .page-content {
  padding: 20px;
  flex-grow: 1;
}

.page-wrapper .navbar-brand {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-wrapper header ul li a {
  color: #fff;
}

.page-wrapper .logo a {
  display: block;
}

.custom-card svg {
  padding: 10px;
  height: 50px;
  width: 50px;
  color: white;
  border-radius: 50px;
  display: inline-block;
  box-shadow: #00000013 0px 2px 4px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
}

.custom-nav .nav-item {
  background-color: var(--primary-light);
}

.page-content .card .card-title {
  text-transform: uppercase;
  font-size: 16px;
}

.admin-layout table th,
.admin-layout table td {
  white-space: nowrap;
}

.file-input__input {
  opacity: 0;
  cursor: pointer;
  overflow: hidden;
  position: absolute;

}

.file-input__label {
  display: flex;
  cursor: pointer;
  font-weight: 400;
  padding: 8px 12px;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  color: #fff !important;
  text-transform: uppercase;
  background-color: var(--primary-clr);
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.file-input__label svg {
  height: 20px;
  margin-right: 10px;
}

.ck-toolbar__items .ck-file-dialog-button+div {
  display: none
}

.page-item .page-link {
  color: #036;
}

.page-item .page-link:hover {
  color: #fff;
  border-color: #036;
  background-color: #036;
}

.page-item.active .page-link,
.page-link:focus {
  color: #fff;
  box-shadow: none;
  border-color: #036;
  background-color: #036;
}

/* .searchWrapper, */
.multiselect-dropdown.css-wmy1p7-ReactDropdownSelect {
  min-height: 40px !important;
  padding: 8px 10px !important;
  border-radius: 4px !important;
  -webkit-border-radius: 4px !important;
  -moz-border-radius: 4px !important;
  -ms-border-radius: 4px !important;
  -o-border-radius: 4px !important;
}

.multiselect-dropdown.css-wmy1p7-ReactDropdownSelect:focus-within {
  border-color: #6c0345;
  box-shadow: none;
}

.searchWrapper {
  padding: 8px !important;
  min-height: 48px !important;
  border: 1px solid #dededede !important;
}

.multiselect-dropdown.css-wmy1p7-ReactDropdownSelect:focus {
  outline: 0px !important;
  box-shadow: none !important;
}

.MuiChip-root {
  height: 26px !important;
  font-size: 10px !important;
}

.tags-input {
  display: grid;
  align-items: flex-start;
  flex-wrap: wrap;
  min-height: 48px;
  width: 100%;
  padding: 0 8px;
  border: 1px solid #d6d8da;
  border-radius: 6px;
}

#tags {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 8px 0 0 0;
}

.tag {
  width: auto;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 0 8px;
  font-size: 14px;
  list-style: none;
  border-radius: 20px;
  margin: 0 8px 8px 0;
  background: #2d6a4f;
  text-transform: capitalize;
}

.tag .tag-title {
  margin-top: 3px;
}

.tag .tag-close-icon {
  display: block;
  width: 16px;
  height: 16px;
  line-height: 13px;
  text-align: center;
  font-size: 14px;
  margin-left: 8px;
  color: #2d6a4f;
  border-radius: 50%;
  background: #fff;
  cursor: pointer;
}

.tags-input input {
  flex: 1;
  border: none;
  height: 46px;
  font-size: 14px;
  padding: 4px 0 0 0;
}

.tags-input input:focus {
  outline: 0px;
}

.tag .tag-close-icon svg {
  width: 15px;
  height: 15px;
}