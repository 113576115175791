@media screen and (max-width:900px) {
    #Search {
        margin-left: auto;
    }

    .page-heading {
        padding-left: 35px;
    }
}

@media screen and (max-width:820px) {

    .lead-sub-title,
    .lead-title {
        text-wrap: unset;
        overflow: unset;
        text-overflow: unset;
        white-space: unset;
    }

    .link-img-wrapper img {
        height: 100px;
    }
}

@media screen and (max-width:600px) {
    .link-img-wrapper img {
        height: 50px;
    }

    #chat-header a,
    #chat-header button,
    .primary-blue-btn {
        font-size: 0.60rem;
    }

    .user-header ul.user-header-ul {
        width: 100%;
        display: grid;
        grid-gap: 10px;
        grid-template-columns: 1fr 1fr;
    }

    .user-header ul.user-header-ul a {
        width: 100%;
        text-align: center;
    }
}