@tailwind base;
@tailwind components;
@tailwind utilities;

/* Admin-Panel */

.admin-nav {
  @apply w-full justify-start border-[2px] border-solid hover:border-green1-light border-green1-dark bg-green1-dark text-white;
}

.admin-nav.active {
  @apply w-full border-[2px] border-solid hover:border-green1-light border-green1-light bg-green1-light hover:bg-green1-x-dark text-white;
}

.custom-tbl.custom-tbl th {
  @apply bg-green1-dark;
}

.tab-btn {
  @apply border-[1px] border-primary-light hover:border-primary-main text-primary-main;
}

.tab-btn.active {
  @apply bg-primary-light hover:bg-primary-main text-white;
}

.transition-custom {
  transition: all ease 400ms;
  -webkit-transition: all ease 400ms;
  -moz-transition: all ease 400ms;
  -ms-transition: all ease 400ms;
  -o-transition: all ease 400ms;
}

.footer-menu {
  @apply text-center sm:text-start;
}

.user-header .cus-nav-link {
  @apply px-[8px] sm:px-[20px];
}

.primary-blue-btn {
  @apply text-nowrap font-medium text-xs sm:text-sm text-white bg-primary-light hover:bg-primary-main;
  background: linear-gradient(
    90deg,
    rgba(48, 141, 185, 1) 0%,
    rgba(2, 43, 50, 1) 100%
  );
}

.bottom-link-card:hover svg {
  transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  transition: all 500ms;
  -webkit-transition: all 500ms;
  -moz-transition: all 500ms;
  -ms-transition: all 500ms;
  -o-transition: all 500ms;
}

.playlist-tabs {
  @apply text-primary-light font-medium;
}

.playlist-tabs.active {
  @apply border-b-[3px] border-solid rounded-none border-primary-light;
}

/* Admin-Panel */

.nav-link {
  /* @apply bg-red-300 sm:bg-green-300 md:bg-blue-300 lg:bg-purple-300 xl:bg-pink-300 2xl:bg-yellow-300 */
}

img {
  display: inline-block;
}

/* ----------------- */
@media (min-width: 560px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 698px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 914px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1120px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1336px;
  }
}
