@import "./constants.css";
/* @import './islam.css'; */

/* Islam.css */
#Footer a:not(.social-icons a) {
  color: var(--primary-light);
}
.MuiSwitch-track {
  /* background-color: #6c0345 !important; */
  background-color: var(--primary-clr) !important;
}
.form-check-input:checked {
  /* background-color: #6c0345 !important;
  border-color: #6c0345 !important; */
  background-color: var(--primary-clr);
  border-color: var(--primary-clr);
}
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.blinking {
  animation: blink 5s step-start infinite;
}

/* Top Banners */

.bred {
  border: 1px solid red !important;
}

.background-overlay::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-size: cover;
}

/* Headings & Paragraphs */

.lead-title {
  font-size: 40px;
  font-weight: 800;
  letter-spacing: 0;
  color: var(--primary-light);
  margin-bottom: 10px;
  max-width: 100%;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
}

.lead-sub-title {
  font-size: 24px;
  font-weight: 400;
  max-width: 800px;
  /* text-transform: capitalize; */
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.author {
  font-size: 18px;
}

.lead-para {
  font-size: 18px;
  font-weight: 300;
  line-height: 1.2;
  letter-spacing: 0;
  color: var(--primary-light);
  margin-bottom: 5px;
  max-width: 1000px;

  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.f-letter {
  display: list-item;
}

.main-title {
  font-size: 40px;
  line-height: 50px;
  letter-spacing: 0;
  color: var(--primary-dark);
}

.primary-title {
  font-size: 34px;
  line-height: 45px;
  letter-spacing: 0;
  margin-bottom: 15px;
  color: var(--primary-dark);
}

.secondary-title {
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: 0;
  margin-bottom: 15px;
  color: var(--secondary-dark);
}

.paragraph {
  padding-bottom: 10px;
  color: var(--secondary-dark);
}

.series-name {
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0;
}

.customize-card,
.paper-card {
  transition: all 0.3s;
}

.doc-img {
  display: grid;
  place-items: center;
  min-height: 150px;
}

.doc-img svg {
  font-size: 125px;
  margin: auto;
}

.customize-card:hover,
.paper-card:hover {
  /* box-shadow: 0px 16px 48px 0px #0000004d !important; */
  transform: scale(1.08);
}

.customize-card .title {
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}

.customize-card .bottom-tile {
  font-size: 14px;
  font-weight: 500;
}

/* Constant */

.social-icons a {
  width: 30px;
  padding: 0px;
  height: 30px;
  display: grid;
  place-items: center;
  border-radius: 30px;
  color: var(--primary-dark);
  transition: all 0.2s ease-in;
  background: var(--primary-light);
}

.primary-btn {
  border: 1px solid var(--secondary-clr);
  font-weight: 600;
  padding: 10px 20px;
  font-family: "Jost";
  border-radius: 0;
  display: inline-block;
  background-color: var(--secondary-clr) !important;
  box-shadow: 0 0 3px #00000038;
  color: var(--primary-clr) !important;
  transition: all 0.3s;
  text-transform: uppercase;
}

.primary-btn:hover {
  background: var(--primary-clr);
  color: var(--primary-light);
}

.primary-bg {
  background-color: var(--primary-clr);
}

.a-link {
  text-decoration: none;
  color: var(--secondary-dark) !important;
}

.search-items li {
  font-size: 16px;
  font-weight: 500;
}

/* ---------------- */

.info-box {
  font-size: 14px;
  line-height: 20px;
}

.header-top a:not(.primary-btn, .social-icons a) {
  color: var(--primary-light);
}

.header-top .primary-btn {
  font-weight: 600;
  color: var(--primary-clr);
  background-color: var(--secondary-clr);
}

.header-top .primary-btn:hover {
  /* font-weight: unset; */
  color: var(--secondary-clr);
  background-color: var(--primary-clr);
}

.custom-header-navbar .nav-link {
  white-space: pre;
  font-size: 15px;
  font-weight: 500;
  color: var(--secondary-clr);
  font-family: "lato";
  text-transform: capitalize;
}

.custom-header-navbar .dropdown-menu {
  left: auto;
  right: 0;
  padding: 0;
  border-radius: 0;
}

.custom-header-navbar .dropdown-item:not(.sub-dropdown-item) {
  text-transform: uppercase;
}

.custom-header-navbar .dropdown-menu > .dropdown-item {
  text-transform: capitalize;
}

.custom-header-navbar .sub-dropdown-item {
  text-transform: capitalize;
  font-size: 15px;
  padding-left: 40px;
}

.password-eyes {
  bottom: 8px;
  right: 5px;
  transform: translateY(-50%);
  cursor: pointer;
}

.customize-card img {
  height: 150px;
  width: 100%;
  object-fit: cover;
}

.customize-card .link-title {
  font-size: 17px;
  line-height: 22px;
  letter-spacing: 0;
  margin-bottom: 5px;
  color: var(--primary-dark);
}

.votes svg {
  font-size: 20px;
}

.filter-tab {
  display: inline-block;
  margin: 0;
  background-color: #fff;
  min-width: 140px;
  border: 0 !important;
  border-bottom: 1px solid #d1d9e2 !important;
  box-shadow: 0 4px 8px #00000014 !important;
  align-items: center;
  padding: 12px 30px 12px 12px;
  cursor: pointer;
}

.vid-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.single-video-wrap .card img {
  max-height: 220px;
}

#dropdown-dot {
  font-size: 28px;
  padding: 0;
}

#dropdown-dot:after {
  display: none;
}

/* Article */

#Article .primary-title {
  font-size: 30px;
  line-height: 1.2;
  max-width: 85%;
}

#Article .secondary-title {
  font-size: 20px;
  line-height: 1.2;
}

#Article .lead-gen,
#SingleArticle .lead-gen {
  background-color: #f0f8ff !important;
}

/* Single-Article */

#SingleArticle .main-sec .primary-title {
  max-width: 600px;
  margin-bottom: 15px;
}

#SingleArticle .main-sec img {
  width: 100%;
}

.title-a {
  font-size: 80px;
  max-width: 400px;
  margin: 0;
}

#SingleArticle h4.primary-title {
  border-left: 2px solid white;
  padding-left: 20px;
}

#Topics .topic-category a {
  color: var(--primary-dark);
}

/* -------------- */

.cover-image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.cover-overlay::before {
  height: 100%;
  content: "";
  /* content: 'Video Cover Image'; */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #00000080;
  display: grid;
  place-items: center;
  color: white;
  font-size: 40px;
}

/* -------------- */

.preview-btn .primary-btn {
  background-color: transparent;
  border: 5px solid var(--secondary-clr);
  color: var(--primary-light);
}

.preview-btn .primary-btn:hover {
  background-color: var(--secondary-clr) !important;
  border: 5px solid transparent !important;
  color: var(--primary-clr) !important;
}

.preview-btn {
  right: 80px;
  top: 50%;
  transform: translateY(-50%);
}

#Topics .primary-title {
  font-size: 28px;
}

#SingleVideo .card-text a {
  color: var(--primary-clr);
  font-weight: 500;
}

#SingleVideo .card-text p {
  font-size: 18px;
}

.header-main .dropdown-toggle::after {
  vertical-align: 0.2em;
}

.search-field input {
  border-radius: 0;
  color: var(--primary-light);
  transition: all 0.3s ease-in-out;
  background-color: var(--primary-clr);
  border: 2px solid var(--secondary-clr);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}

.search-field input:focus {
  border: 2px solid var(--secondary-clr);
}

.search-field input::placeholder {
  color: var(--secondary-light);
  color: #a9a9a9;
  font-size: 14px;
}

.search-field button {
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background-color: transparent;
  padding: 1px;
  border: 0;
  height: 90%;
  width: 30px;
}

.search-field button:hover,
.search-field button:focus,
.search-field button:active {
  background-color: transparent !important;
  border: 0 !important;
}

#SearchItem .search-field button {
  color: var(--primary-clr) !important;
}

#SearchItem .search-field input {
  border-color: var(--primary-clr);
  background-color: transparent !important;
}

.main-sec:not(#Article .main-sec, #SingleArticle .main-sec) {
  min-height: 300px;
}

.vid-wrapper {
  height: 300px;
  width: 100%;
}

.vid-wrapper-overlay {
  place-items: center;
  transition: all 0.3s;
  background-color: #0000004f;
  cursor: pointer;
}

.vid-wrapper-overlay svg {
  height: 60px;
  width: 60px;
  color: var(--primary-light);
  background-color: var(--secondary-clr);
  border-radius: 50%;
  transition: all 0.3s;
}

.vid-wrapper-overlay:hover svg {
  transform: scale(1.2);
}

.vid-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* -----------------USER---------------------USER--------------------USER--------------- */
.heading-h1 {
  font-size: 48px;
  line-height: 60px;
  color: var(--secondary-dark);
}

.heading-h2 {
  font-size: 24px;
  line-height: 36px;
  color: var(--secondary-dark);
}

.title-t1 {
  font-size: 18px;
  color: var(--secondary-dark);
  min-height: 22px;
}

.series-pagination a {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  background-color: #f5f5f5;
}

.series-pagination a.active {
  background-color: var(--secondary-clr);
}

.otp-parent input:focus,
.otp-parent input {
  height: 50px;
  outline: none;
  border: 1px solid #ccc;
  width: 50px !important;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.playlist-modal .modal-dialog,
.Otp-modal .modal-dialog {
  max-width: 400px !important;
}

.playlist-modal .modal-content {
  border: 0px;
  border-radius: 0px;

  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
}

.custom-btn1,
.btns-in-user button {
  width: fit-content;
  padding: 6px;
  background: transparent !important;
  color: var(--primary-dark);
  border: none;
  border-radius: 0;
}

.custom-btn1 svg,
.btns-in-user button svg {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  margin-right: 10px;
  padding: 5px;
  background-color: var(--primary-clr);
  color: var(--primary-light);
}

#SingleSeries .MuiTabs-indicator {
  background-color: var(--primary-clr);
}

#SingleSeries .MuiButtonBase-root {
  color: var(--primary-clr);
}

/* -----------------USER---------------------USER--------------------USER--------------- */

.custom-input-field {
  width: 100%;
  padding: 10px;
  font-size: 17px;
  border: 1px solid #dededede;
  border-radius: 4px;
}

.popup-btn {
  background-color: var(--primary-clr) !important;
  border-radius: 0 !important;
  border: 2px solid var(--secondary-clr) !important;
  color: white !important;
}

:is(#Library, #Watchleter, #WatchHistory, #Playlist, #Favorite)
  .customize-card
  button.bg-danger {
  padding: 4px;
  opacity: 0.7;
  height: 24px;
  width: 24px;
}

:is(#Library, #Watchleter, #WatchHistory, #Playlist, #Favorite)
  .customize-card
  button.bg-danger:hover {
  opacity: 1;
}

a.chat-link,
.user-header ul a {
  font-size: 13px;
  font-weight: 500;
  padding: 5px 20px;
  border-radius: 30px;
  color: var(--primary-clr);
  background-color: #f4f5f6;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

a.chat-link,
a.chat-link:hover,
.user-header ul a.active,
.user-header ul a:hover {
  color: #fff !important;
  background-color: var(--primary-clr);
}

.chat-input-group {
  /* overflow: hidden; */
  border-radius: 12px;
  /* border: 2px solid #e4edf1; */
  box-shadow: 0 8px 12px -2px rgba(19, 21, 22, 0.04),
    0 4px 8px rgba(19, 21, 22, 0.02);
}

.chat-input-group textarea.form-control {
  height: 50px;
  resize: none;
  font-weight: 600;
  overflow-y: auto;
  border-radius: 12px;
  padding-right: 120px;
  border: 2px solid #e4edf1;
}

.chat-input-group-custom textarea.form-control {
  min-height: 50px;
  height: auto;
  resize: none;
  font-weight: 600;
  overflow-y: auto;
  border-radius: 12px;
  padding-right: 120px;
  border: 2px solid #e4edf1;
}

.chat-block::-webkit-scrollbar,
.overflow-auto::-webkit-scrollbar,
.custom-textarea::-webkit-scrollbar,
.css-1jmuchi-DropDown::-webkit-scrollbar,
.chat-input-group textarea.form-control::-webkit-scrollbar {
  width: 5px !important;
  background-color: #fff;
}

#chat-body pre > div::-webkit-scrollbar {
  background-color: #fcd804;
  height: 10px !important;
}

#chat-body pre::-webkit-scrollbar-thumb,
#chat-body pre > div::-webkit-scrollbar-thumb,
.chat-block::-webkit-scrollbar-thumb,
.overflow-auto::-webkit-scrollbar-thumb,
.custom-textarea::-webkit-scrollbar-thumb,
.css-1jmuchi-DropDown::-webkit-scrollbar-thumb,
.chat-input-group textarea.form-control::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: var(--primary-clr);
}

.up-arrow-btn,
.chat-input-group .chat-btn:focus,
.chat-input-group .chat-btn {
  padding: 4px;
  border-radius: 6px;
  color: var(--primary-clr);
  transform: translateY(-50%);
  background-color: #e2e2e2 !important;
  transition: all 400ms ease;
  border: 1px solid transparent;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  position: absolute;
  bottom: -10px;
  right: 12px;
}

.chat-input-group .ochat-btn:focus,
.chat-input-group .ochat-btn {
  padding: 4px;
  border-radius: 6px;
  color: var(--primary-clr);
  transform: translateY(-50%);
  background-color: #e2e2e2 !important;
  transition: all 400ms ease;
  border: 1px solid transparent;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}
.chat-input-group .ochat-btn-left:active {
  color: var(--primary-clr);
  border: none;
}
.chat-input-group .ochat-btn-left:focus,
.chat-input-group .ochat-btn-left {
  padding: 4px;
  border-radius: 6px;
  color: var(--primary-clr);
  transform: translateY(-50%);
  background-color: #fff !important;
  transition: all 400ms ease;
  border: 1px solid transparent;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}

.chat-btns {
  position: absolute;
  bottom: -12px;
  right: 12px;
}

.chat-btns-right {
  position: absolute;
  bottom: -12px;
  right: 10px;
}

.chat-input-group .chat-btn:active,
.chat-input-group .chat-btn:hover {
  border: 1px solid var(--primary-clr);
}

#duartion-bar,
:is(#Favorite, #Watchleter, #WatchHistory, #Playlist)
  .customize-card
  .MuiSlider-root {
  padding: 0;
  height: 6px;
  border-radius: 0;
  color: var(--secondary-clr);
}

#duartion-bar .MuiSlider-thumb,
:is(#Favorite, #Watchleter, #WatchHistory, #Playlist)
  .customize-card
  .MuiSlider-thumb {
  display: none;
}

#doc-viewer {
  max-width: calc(100% - 200px);
  margin: auto;
}

#doc-viewer .modal-body::-webkit-scrollbar {
  display: none;
}

/* Chat */

.chat-sidebar {
  top: 0;
  left: 0;
  bottom: 0;
  position: fixed;
  overflow-x: hidden !important;
  /* background-color: var(--primary-clr); */
  background: linear-gradient(
    90deg,
    rgba(48, 141, 185, 1) 0%,
    rgba(2, 43, 50, 1) 100%
  );
}

#ChatSidebar img {
  width: 120px;
  object-fit: cover;
  margin-right: 15px;
}

#ChatSidebar {
  /* background-color: var(--primary-clr) !important; */
  background: linear-gradient(
    90deg,
    rgba(48, 141, 185, 1) 0%,
    rgba(2, 43, 50, 1) 100%
  );
  color: var(--primary-light);
}

#ChatSidebar .menu-item {
  display: flex;
  color: var(--primary-light) !important;
}

#ChatSidebar .menu-item.active {
  background-color: var(--secondary-clr) !important;
  color: #000 !important;
}

#ChatSidebar .menu-item span:nth-child(1) {
  margin-right: 10px;
}

#ChatSidebar .menu-item span[class] {
  flex-grow: 1;
  display: flex;
  margin-right: 0px;
}

#ChatSidebar .menu-item span:nth-child(2) {
  width: 160px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

body[dir="rtl"] #ChatSidebar .menu-item span:nth-child(1) {
  margin-right: 0px;
  margin-left: 10px;
}

#Chat #basic-button .MuiSvgIcon-root {
  fill: var(--secondary-dark);
}

#Chat .prompt-wrap {
  background-color: var(--primary-clr);
}

#Chat .prompt-wrap input {
  padding-left: 35px;
}

#Chat .prompt-wrap span {
  position: absolute;
  left: 8px;
  top: 5px;
}

#Chat .q-link {
  border: 1px solid #d0d0d0;
  color: var(--primary-dark);
}

#Chat .prompt-title p:nth-child(1) {
  font-weight: 600;
}

#Chat .prompt-title p:nth-child(2) {
  font-size: 12px;
  color: #838383;
}

.chat-response {
  background: #ecf0f4;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}

.chat-response .question {
  font-weight: 700;
}

.chat-response .answer-content {
  font-weight: 500;
}

.prompt-small-icon {
  width: 35px;
  height: 35px;
  color: #fff;
  border-radius: 50%;
  background: var(--primary-clr);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.chat-btn-thm-1:active,
.chat-btn-thm-1:focus-visible,
.chat-btn-thm-1 {
  background-color: transparent !important;
  border: 1px solid var(--primary-clr) !important;
  color: var(--primary-clr) !important;
}

.prompt-wrap button:active,
.prompt-wrap button:focus-visible,
.prompt-wrap button {
  border-color: white !important;
  color: white !important;
}

#User-Index .index-container {
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
}

#User-Index .btn-thm-1 span:has(svg) {
  background-color: transparent;
  border: 2px solid var(--primary-clr);
  color: var(--primary-clr);
  /* margin: 0; */
}

#User-Index .link-title {
  font-weight: 600;
  font-size: 20px;
}

.react-multi-carousel-track li.series-item:first div.p-2.h-100 {
  padding-left: 0px !important;
}

a.link-title.f-letter::marker {
  display: none;
}

button.home-tab:hover,
button.home-tab:focus,
button.home-tab {
  height: 30px;
  border-radius: 0;
  box-shadow: none;
  padding-left: 5px;
  padding-right: 5px;
  background: #fff;
  color: var(--primary-dark);
  border-bottom: 5px solid #fff;
  text-transform: capitalize;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
}

button.home-tab.active {
  box-shadow: none;
  font-weight: 500;
  border-bottom: 5px solid var(--primary-clr);
}

#User-Index h3 {
  font-size: 24px !important;
}

.shadow-1 {
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
}

/* --------------- */

:is(
    #continue-watched,
    #recommended,
    #more-topics,
    #continue-watching,
    #new-items,
    #latest-series,
    #docs-films,
    #series-item,
    #topic-item
  )
  .react-multi-carousel-list {
  height: 150px;
  overflow-x: clip;
  overflow-y: visible;
}

:is(
    #continue-watched,
    #recommended,
    #more-topics,
    #continue-watching,
    #new-items,
    #latest-series,
    #docs-films,
    #series-item,
    #topic-item
  )
  :is(.customize-card, .paper-card)
  .card-body {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: var(--primary-light);
  z-index: 9999;
}

:is(
    #continue-watched,
    #recommended,
    #more-topics,
    #continue-watching,
    #new-items,
    #latest-series,
    #docs-films,
    #series-item,
    #topic-item
  )
  :is(.customize-card, .paper-card):hover
  .card-body {
  display: block;
  z-index: 9999;
}

#continue-watching,
#continue-watched {
  z-index: 900;
}

#latest-series {
  z-index: 800;
}

#series-item {
  z-index: 700;
}

#recommended,
#new-items {
  z-index: 600;
}

#topic-item {
  z-index: 500;
}

#docs-films {
  z-index: 400;
}

#more-topics {
  position: relative;
  z-index: 100;
}

.btn-thm-2:active,
.btn-thm-2:hover,
.btn-thm-2 {
  background-color: var(--primary-clr);
  border: 0px solid var(--primary-clr);
  color: white;
  border-radius: 0;
}

.up-arrow-btn {
  display: none;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #ccc;
}

.up-arrow-btn:hover {
  background-color: #ccc;
  border: 1px solid #ccc;
  color: #062645;
}

.prompt:hover .up-arrow-btn {
  display: inline-block;
}

.accor-btn:active,
.accor-btn:hover,
.accor-btn:focus-visible,
.accor-btn {
  background-color: transparent !important;
  border: none !important;
  text-align: start !important;
}

.accor {
  /* background-color: #75064c; */
  background-color: var(--primary-clr);
}

.project-name {
  color: #000;
  font-size: 16px;
}

.project-options span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.project-dropdown.dropdown-toggle::after {
  display: none;
}

.project-dropdown:focus-visible,
.project-dropdown:focus,
.project-dropdown:hover,
.project-dropdown {
  color: #000;
  background: transparent;
  outline: 0px !important;
  box-shadow: none !important;
}

#project-appbar ul {
  padding: 0px;
}

.project-abbr {
  overflow: hidden;
}

.project-abbr a,
.project-abbr button {
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.project-abbr button {
  padding: 8px 16px;
}

.project-abbr a svg,
.project-abbr button svg {
  font-size: 16px;
}

.project-abbr .btn-thm-1:hover {
  color: var(--primary-clr);
}

.leading-loose a {
  font-size: 14px;
  color: var(--primary-clr);
}

.loading {
  font-size: 70px;
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  text-align: center;
}

.loading06 span {
  position: relative;
  color: rgba(0, 0, 0, 0.2);
}

.loading06 span::after {
  position: absolute;
  top: 0;
  left: 0;
  content: attr(data-text);
  color: #fff;
  opacity: 0;
  transform: rotateY(-90deg);
  animation: loading06 4s infinite;
}

.loading06 span:nth-child(2)::after {
  animation-delay: 0.1s;
}

.loading06 span:nth-child(3)::after {
  animation-delay: 0.2s;
}

.loading06 span:nth-child(4)::after {
  animation-delay: 0.3s;
}

.loading06 span:nth-child(5)::after {
  animation-delay: 0.4s;
}

.loading06 span:nth-child(6)::after {
  animation-delay: 0.5s;
}

.loading06 span:nth-child(7)::after {
  animation-delay: 0.6s;
}

@keyframes loading06 {
  0%,
  75%,
  100% {
    transform: rotateY(-90deg);
    opacity: 0;
  }

  25%,
  50% {
    transform: rotateY(0);
    opacity: 1;
  }
}

.btn-thm-1 .multiselect-dropdown.css-wmy1p7-ReactDropdownSelect:focus,
.btn-thm-1 .multiselect-dropdown.css-wmy1p7-ReactDropdownSelect {
  border: 0px;
  box-shadow: none;
  padding: 5px !important;
}

.logo-sec {
  /* background: var(--primary-clr); */
}

.page-notfound {
  color: var(--primary-clr);
}

.page-notfound h1 {
  font-size: 75px;
  font-weight: 800;
  text-transform: uppercase;
}

.page-notfound a {
  color: #fff;
  height: 40px;
  width: 150px;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  background: var(--primary-clr);
}

.scraping-tab-ul {
  background: #eaebec;
}

.scraping-tab-ul .nav-link {
  font-weight: 600;
  text-transform: capitalize;
  color: var(--secondary-dark);
}

.scraping-tab-ul .nav-link.active,
.scraping-tab-ul .show > .nav-link {
  background: #fff;
  color: var(--primary-clr);
}

.upload-doc {
  border: 1px dashed var(--primary-clr);
}

.upload-doc input {
  cursor: pointer;
}

.tab-small-text {
  color: var(--secondary-dark);
  font-weight: 400;
  font-size: 12px;
}

.image-up-icon svg {
  font-size: 34px;
  color: var(--primary-clr);
}

.files-ul a:hover,
.files-ul a:focus,
.files-ul a {
  color: var(--secondary-dark);
}

table.table tr > th {
  color: #fff;
  font-weight: 400;
  text-transform: capitalize;
  background: var(--primary-clr);
}

table.table tr > th {
  padding: 0.5rem;
}

table.table a,
table.table button {
  font-weight: 400;
  color: var(--primary-clr);
}

.table-file-title {
  font-size: 12px;
}

.edit-file-name button {
  position: absolute;
  right: 4px;
  top: 50%;
  transform: translateY(-50%);
}

.status-btn input {
  display: none;
}

.status-btn label {
  cursor: pointer;
}

.status-btn .form-check {
  margin: 0;
  padding: 4px 8px;
  transition: 300ms ease;
  border-radius: 6px;
}

.status-btn .form-check:has(input:checked) {
  background-color: var(--primary-clr);
  color: var(--primary-light);
}

.customize-card .bottom-tile span {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.customize-card .sub-title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.author-name {
  line-height: 1;
  font-size: 14px;
  vertical-align: top;
}

.MuiAvatar-root img {
  width: 110%;
  height: 110%;
}

/* New card */

.paper-card img {
  height: 150px;
  width: 100%;
  object-fit: cover;
}

.paper-card .title {
  margin: 0;
  font-size: 17px;
  font-weight: 600;
  overflow: hidden;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  /* text-transform: capitalize; */
  color: var(--primary-dark);
}

.author-tile h6 {
  line-height: 1;
  overflow: hidden;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  /* text-transform: capitalize; */
  color: var(--primary-dark);
}

/* ----- */
.HomePageMedia .MuiButton-root {
  color: var(--primary-clr) !important;
  text-transform: capitalize;
  font-weight: 600;
}

.HomePageMedia .MuiButton-root .MuiButton-startIcon {
  border: 2px solid var(--primary-clr);
  border-radius: 100px;
  padding: 4px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
}

.HomePageMedia .MuiButton-root .MuiButton-startIcon svg {
  color: var(--primary-clr) !important;
}

.border-icn-btn svg {
  width: 25px;
  height: 25px;
}

.border-icn-btn {
  color: var(--primary-clr) !important;
  padding: 2px !important;
  border: 2px solid var(--primary-clr) !important;
}

#Author .paragraph img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  max-height: 400px;
}

.paragraph img {
  max-width: 400px;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.google-login-btn {
  font-size: 18px;
  font-weight: 600;
  border-radius: 8px;
  padding: 10px 29px !important;
  background: #fff !important;
  transition: all 0.3s ease-in-out;
  color: var(--primary-clr) !important;
  border: 2px solid var(--primary-clr) !important;
}

/* Media Q */

.social-icons svg {
  font-size: 18px;
}

.paper-card {
  position: relative;
  z-index: auto;
}

.paper-card:hover {
  z-index: 9;
}

.paper-card .card-body {
  left: 0;
  right: 0;
  top: 100%;
  display: none;
  z-index: 999;
  position: absolute;
  background-color: white;
}

.paper-card:hover .card-body {
  display: block;
  z-index: 9999;
}

#User-Index .video-banner video {
  height: 500px !important;
}

.project-info {
  top: 100%;
  display: none;
  transition: all 0.3s linear;
}

button[aria-current="true"] {
  color: #fff !important;
  background: #2d6a4f !important;
}

.css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root:hover {
  color: #fff !important;
  background: #2d6a4f !important;
}

.featured-article {
  background-image: linear-gradient(
    to bottom,
    transparent,
    var(--primary-dark)
  );
}

.featured-article h6 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

#ChatSidebar .menu-item .about-info .css-1pxa9xg-MuiAlert-message {
  font-size: 11px;
  padding: 4px 0;
}

#ChatSidebar .menu-item .about-info .css-1vooibu-MuiSvgIcon-root {
  width: 0.7em;
  height: 0.7em;
}

#Reader .about-info-container .about-info,
#ChatSidebar .menu-item .about-info {
  display: none;
}

#Reader .about-info-container:hover .about-info,
#ChatSidebar .menu-item:hover .about-info {
  top: 30px;
  display: inline-block;
}

.tag-text {
  color: #000;
  display: inline;
  font-weight: 500;
  font-size: 9px;
  padding: 2px 12px;
  border-radius: 15px;
  display: flex;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  background: var(--secondary-clr);
}

.video-play-button {
  width: 60px;
  height: 60px;
  z-index: 2;
  border-radius: 50%;
  background-color: var(--primary-clr);
}

.video-play-button svg {
  font-size: 44px;
}

.zoom-in-zoom-out {
  animation: zoom-in-zoom-out 2s ease-out infinite;
}

.blink-soft {
  animation: blinker 4s linear infinite;
}
@keyframes blinker {
  50% {
    opacity: 0.1;
  }
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.5, 1.5);
    -webkit-transform: scale(1.5, 1.5);
    -moz-transform: scale(1.5, 1.5);
    -ms-transform: scale(1.5, 1.5);
    -o-transform: scale(1.5, 1.5);
  }

  100% {
    transform: scale(1, 1);
  }
}

.gift-btn span {
  margin-right: 0px;
}

.slick-prev:before,
.slick-next:before {
  color: var(--primary-clr) !important;
}

.MuiContainer-root:has(.react-multi-carousel-list) {
  max-width: 100vw;
}

.react-dropdown-select.multiselect-dropdown.me-2.language-dropdown:focus,
.react-dropdown-select.multiselect-dropdown.me-2.language-dropdown {
  border: 2px solid #e4edf1;
  height: 50px;
  box-shadow: none !important;
  outline: 0px !important;
  border-radius: 12px !important;
  -webkit-border-radius: 12px !important;
  -moz-border-radius: 12px !important;
  -ms-border-radius: 12px !important;
  -o-border-radius: 12px !important;
}

.language-modal .MuiDialog-container > div,
.language-modal .language-content {
  overflow: visible;
}

.language-close-button:hover,
.language-close-button:focus,
.language-close-button {
  background: var(--primary-clr);
}

.language-close-button svg {
  color: #fff;
}

.use-prompt-text:focus,
.use-prompt-text {
  outline: 0px;
  padding: 10px;
  box-shadow: none;
  border-radius: 4px;
  border: 1px solid #dee2e6;
  white-space: break-spaces;
}

.Otp-modal .modal-content {
  background: var(--primary-clr);
}

.react-dropdown-select-input::placeholder {
  color: #333;
  font-weight: 500;
}

.warning-text {
  font-weight: 500;
  text-align: center;
  font-style: italic;
}

#Botsonic-wrapper {
  right: 0px !important;
  left: unset !important;
}

#widget-Botsonic {
  outline: 0px;
}

#ChatSidebar .menu-item.active .tag-text {
  color: #fff;
  background: var(--primary-clr);
}

.css-6hp17o-MuiList-root-MuiMenu-list {
  padding: 0px;
}

.markdown-body {
  background-color: transparent !important;
  color: #333 !important;
  font-weight: 400;
}

.markdown-body .highlight pre,
.markdown-body pre {
  background-color: #e1eded !important;
}

.markdown-body .highlight pre,
.markdown-body pre {
  background-color: var(--primary-clr) !important;
}

.ql-editor {
  min-height: 200px;
  font-size: 15px;
  font-family: "Lato", sans-serif !important;
  border: 1px solid #dee2e6;
  border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  -o-border-radius: 5px !important;
}

.dalle-image .dalle-image-action {
  display: none;
}

.dalle-image:hover .dalle-image-action {
  display: block;
}

.dalle-image:hover button {
  color: #fff;
  background: #000000ba;
}

.modify-question input.form-control {
  border: 2px solid #e4edf1;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
}

.modify-question .update-btn {
  text-transform: uppercase;
  font-weight: 500;
  color: #fff;
  background-color: #333;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
}

table.custom-tbl tbody td {
  padding: 0.5rem 0.5rem !important;
}

.custom-textarea {
  resize: none;
  min-height: 50px;
  overflow-y: auto;
}

.body-sec {
  /* background: var(--primary-clr); */
}

#chat-header .react-dropdown-select:focus-within,
#chat-header .react-dropdown-select:hover {
  border-color: var(--primary-clr);
}

#Header .react-dropdown-select:focus-within,
#Header .react-dropdown-select:hover {
  border-color: #fff;
}

#chat-header .react-dropdown-select {
  /* color:var(--primary-clr) */
}

#Header .react-dropdown-select {
  color: #fff;
}

#chat-header .react-dropdown-select-dropdown,
#Header .react-dropdown-select-dropdown {
  border: 0px;
  background: var(--primary-clr);
}

#chat-header .react-dropdown-select-item-selected,
#Header .react-dropdown-select-item-selected {
  background: var(--secondary-clr);
  border: 0px;
}

body[dir="rtl"] a span,
body[dir="rtl"] button span {
  margin-right: -4px;
  margin-left: 8px;
}

table > tr > td > a {
  background: var(--primary-clr) !important;
  color: #fff !important;
}
